<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <error-message-dialog />
    <v-sheet class="overflow-hidden">
      <Loading />
      <!-- マスタ初期登録(取引先一覧) -->
      <NavBar tittle="label.lbl_masterInit" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <!-- 空白 -->
            <span class="label.item-spacer">&nbsp;</span>
            <v-card style="width: 100%; height: 150px; margin-left: 12px; margin-right: 12px">
              <v-card-text>
                <p style="font-size: x-large; font-weight: bold; text-align: left">取引先一覧</p>
                <p style="font-size: large; text-align: left; white-space: pre-line">
                  {{ explanation }}
                </p>
              </v-card-text>
            </v-card>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <div class="btn-search-area" style="float: right">
                <!--モジュール追加ボタン-->
                <v-btn
                  class="other-btn"
                  style="float: right"
                  :disabled="moduleAddDisableFlg"
                  @click="moduleAdd()"
                  >{{ $t("btn.btn_modulAdd") }}</v-btn
                >
                <!--新規登録ボタン-->
                <v-btn class="other-btn" style="float: right" @click="newClient()">{{
                  $t("btn.btn_iniRegist")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <!-- マスタ情報 -->
        <v-data-table
          id="listData"
          :class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
        >
          <!-- 選択 -->
          <template v-slot:[`item.clientSelect`]="{ index }">
            <input
              type="radio"
              v-model="clientSelect"
              :value="index"
              style="transform: scale(2)"
              name="clientSelect"
            />
          </template>
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 取引先追加ダイアログ-->
      <v-dialog v-model="dialogClientAdd" :max-width="500">
        <v-card id="dialogOffice">
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_clientAdd") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-form ref="addClient" lazy-validation>
                <span class="item-spacer">&nbsp;</span>
                <v-row>
                  <!--営業所コード-->
                  <div class="add-textbox-office-info">
                    <v-text-field
                      outlined
                      dense
                      maxlength="3"
                      v-model="officeCd"
                      :label="$t('label.lbl_officeCd')"
                      :disabled="disableEdit"
                    ></v-text-field>
                  </div>
                </v-row>
                <v-row>
                  <!--取引先コード-->
                  <div class="add-textbox-office-info">
                    <v-text-field
                      outlined
                      dense
                      maxlength="7"
                      v-model="addClientCd"
                      :rules="[rules.inputRequired, rules.clientCdLength]"
                      :label="$t('label.lbl_supplierCd')"
                      clear-icon="mdi-close-circle"
                      clearable
                      @input="inputAddClientCd"
                      :error-messages="alertMessage"
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="btn-search-area" style="float: right">
              <v-btn
                color="primary"
                class="other-btn"
                style="float: right"
                @click="newClientMasterIni"
              >
                {{ $t("btn.btn_execute") }}
              </v-btn>
              <v-btn
                color="primary"
                class="other-btn"
                style="float: right"
                @click="cancelClientAdd"
              >
                {{ $t("btn.btn_cancel") }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import linkDownloadMixin from "@/mixins/LinkDownloadMixin";

export default {
  name: appConfig.SCREEN_ID.P_INI_001,
  mixins: [commonMixin, linkDownloadMixin],
  components: {
    Loading,
    sideMenu,
    NavBar,
    SimpleDialog,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      clientCdLength: (value) => (value && value.length == 7) || "7文字で入力してください",
    },

    explanation: "", // 説明

    openMenu: null, // メニュー
    loadingCounter: 0, // ローディング画面表示フラグ
    dialogClientAdd: false, //取引先追加ポップアップ
    disableEdit: false, //取引先追加ポップアップ営業所disable
    moduleAddDisableFlg: false,

    officeCd: "", //営業所コード
    addClientCd: "", //追加取引先コード
    clientSelect: null, //選択取引先
    inputList: [], // 取引先一覧
    clientCdList: [], // 取引先コードリスト

    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },

    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
    alertMessage: "", // 取引先追加ダイアログメッセージ

    tableData: "tableData",

    // ヘッダ
    headerItems: [
      //選択
      {
        text: i18n.tc("label.lbl_select"),
        value: "clientSelect",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // 営業所コード
      {
        text: i18n.tc("label.lbl_officeCd"),
        value: "officeCd",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // 取引先コード
      {
        text: i18n.tc("label.lbl_supplierCd"),
        value: "clientCd",
        width: "7%",
        align: "center",
        sortable: false,
      },

      // 完了フラグ
      {
        text: i18n.tc("label.lbl_completeFlg"),
        value: "completeFlg",
        width: "7%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  created() {
    //
  },

  methods: {
    onBlur() {
      //
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 初期値
     */
    init() {
      this.officeCd = sessionStorage.getItem("office_cd");
      this.getClientList();
      this.explanation =
        "新規導入または取引先追加の場合は新規登録ボタン、モジュール追加の場合は追加したい取引先コードを選択後、モジュール追加ボタンを押してください。";
      this.alertMessage = "";

      //this.$watch();
    },

    /**
     * 取引先一覧取得
     */
    getClientList() {
      // ローディング画面表示ON
      this.loadingCounter++;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所コード
      config.params.officeCd = this.officeCd;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INI_OFFICE_MODULE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            console.debug("getClientList() Response", response); // 成功
            const list = [];
            this.clientCdList = [];
            if (jsonData.resCom.resComCode == "000") {
              // 取引先リストを取得
              jsonData.resIdv.officeModuleList.forEach((row) => {
                list.push({
                  officeCd: this.officeCd, // 営業所コード
                  clientCd: row.clientCd, // 取引先コード
                  completeFlg: row.completeFlg, // 完了フラグ
                });
                this.clientCdList.push(row.clientCd);
              });

              this.inputList = list;

              if (this.inputList.length == 0) {
                this.moduleAddDisableFlg = true;
              } else {
                this.moduleAddDisableFlg = false;
              }
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              // タスクを終了します（失敗）。
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter--;
          });
      });
    },

    /**
     *  新規登録ボタン押下処理
     */
    newClient() {
      this.disableEdit = false;
      this.dialogClientAdd = true;
      this.disableEdit = true;
    },

    /**
     *  モジュール追加ボタン押下処理
     */
    moduleAdd() {
      if (this.clientSelect == null) {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-INI-001_001_E");
      } else {
        this.$router.push({
          name: appConfig.MENU_ID.M_INI_MODULE_SELECT,
          params: {
            // 取引先コード
            clientCd: this.inputList[this.clientSelect].clientCd,
          },
        });
      }
    },

    /**
     *  キャンセルボタン押下処理(取引先追加ダイアログ)
     */
    cancelClientAdd() {
      this.$refs.addClient.resetValidation();
      this.alertMessage = "";
      this.addClientCd = "";
      this.dialogClientAdd = false;
    },

    /**
     *  実行ボタン押下処理(取引先追加ダイアログ)
     */
    newClientMasterIni() {
      if (this.$refs.addClient.validate()) {
        if (this.clientCdList.includes(this.addClientCd)) {
          this.alertMessage = i18n.tc("check.chk_duplicate_clientCd");
          return;
        }
        this.dialogClientAdd = false;
        this.$router.push({
          name: appConfig.MENU_ID.M_INI_MODULE_SELECT,
          params: {
            // 取引先コード
            clientCd: this.addClientCd,
          },
        });
      }
    },

    /**
     *  取引先コード変更時処理(取引先追加ダイアログ)
     */
    inputAddClientCd() {
      this.alertMessage = "";
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
  },
  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    //eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 153px;
}
#listData.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 500px;
  height: 100%;
}

#listData.v-data-table ::v-deep .v-data-table__wrapper {
  width: 100%;
  margin-right: 12px;
}
.textbox {
  width: 11.2rem;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.span1 {
  width: 4.3rem;
}
.span2 {
  width: 8rem;
}
a {
  text-decoration: underline;
}

.cargos_btn ::v-deep .v-btn__content {
  color: #000 !important;
  font-weight: normal !important;
}
.v-btn.v-btn--has-bg.btn_search {
  width: 5.5rem;
}

.date-style {
  width: 11rem;
}
#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

// 検索結果表示件数部分
.display-search-info-label {
  width: 100%;
  margin-top: 20px;
  margin-left: 12px;
  margin-right: 12px;
  font-size: x-large;
  font-weight: bold;
}
</style>
